import { useEffect, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { Button } from '..'
import { Container, Dropdown, Text } from '../..'
import StyledDropdownMenu from './dropdown-menu-styled'
import StyledArrow from './arrow-styled'
import { useParams } from 'react-router-dom'

export const DropdownMenu = ({icon, trigger, noSelectionMessage, onHover, allowMultipleSelection, hideCaret, selectedOption, onChange: onChangeProps, label, options, disabled, arrowDirection}:any) => {
  const initialValues = label?.split(',').reduce((acc:any, curr:string) => ({ ...acc, [curr]: true }), {})
  const [ isExpanded, setIsExpanded ] = useState(false)
  const [ values, setValues ] = useState(initialValues)
  const [ message, setMessage ] = useState('')
  const params = useParams()
  const onClose = () => {
    setIsExpanded(false)
    setValues(initialValues)
  }

  const onChange = (item:any) => {
    onChangeProps(item)
    console.log(item)
    onClose()
  }

  const onUpdateCategories = () => {
    let newValues = ''
    Object.keys(values).forEach((value: string) => { if (values[value]) newValues = `${newValues ? `${newValues},` : ''}${value}` })
    onChangeProps(newValues)
    onClose()
  }

  const renderTrigger = () => {
    const option1  = arrowDirection?.split('-')[0]
    const option2  = arrowDirection?.split('-')[1]
    
    if (trigger) {
      return (
        <Container className={'trigger'} onClick={() => ( !onHover && isExpanded) ? onChange(selectedOption) : setIsExpanded(true)}>
          {trigger}
        </Container>
      )
    }
    
    return (
      <Container expand className={'trigger'} onClick={() => ( !onHover && isExpanded) ? onChange(selectedOption) : setIsExpanded(true)}> 
        {
          label && (
            <Container className={`label-container ${icon ? 'tier-group-label': ''}`}>
              {icon ? <img src={icon} alt="tier icon"/> : null} <Text color='brand'>{label.replaceAll(',',', ')}</Text>
            </Container>
          )
        }
        { selectedOption?.label && <Text>{selectedOption.label}</Text> }
        { selectedOption?.icon }
        { !hideCaret && <StyledArrow className={`arrow ${!isExpanded ? option1 || 'down' : option2 || 'up'}`} ><FiChevronDown size={20} color={`${icon ? "A2B0EE":''}`}/></StyledArrow> }
      </Container>
    )
  }

  useEffect(() => {
    let hasValues = false
    Object.keys(values || {}).forEach((val: any) => {
      if (values[val]) {
        hasValues = true
      }
    })

    if (hasValues) {
      setMessage('')
    } else {
      setMessage(noSelectionMessage)

    }
  }, [values])

  if (allowMultipleSelection) {
    return (
      <StyledDropdownMenu className={`dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}> 
          <Container className={'options'}>
            {
              options.map((option:Record<string, any>) => {
                const onCheckOption = (e:any) => {
                  setValues({ ...values, [option.key]: e.target.checked })
                }
                const isChecked = values[option.key] ? true : false
                return (<Container key={option.key} className={'checkbox'} >
                  <label>
                    <input type={'checkbox'} checked={isChecked} onChange={onCheckOption} />
                    <Text>{option.label}</Text> 
                  </label>
                </Container>)
              })
            }
            {
              message 
              ? (
                <Container className={'message'}>
                  <Text>{message}</Text>
                </Container>
              )
              : (
                <Button onClick={onUpdateCategories}>
                  <Text>{'Apply'}</Text>
                </Button>
              )
            }
          </Container>
        </Dropdown>
      </StyledDropdownMenu>
    )
  }
  const getOptionSelected = (option:Record<string,any>) => option.label.toLowerCase() === params.category?.replaceAll('_', ' ').toLowerCase()
  return (
    <div
    style={{
      display: 'flex',
      height: '100%',
    }}
      onMouseLeave={() => {
        if (onHover) {
          onHover()
          return setIsExpanded(false)
        }
      }}
      onMouseEnter={() =>  {
        if (onHover) {
          onHover()
          return setIsExpanded(true)
        }
      }}
    >
      <StyledDropdownMenu className={`dropdown-selector${disabled ? ' disabled ': ''}`} >
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}> 
          <Container className={`options`}>
            {options.map((option: Record<string, any>) =>  
              <Container key={option.key} className={`${getOptionSelected(option) ? 'option-selected-container' : ''} ${icon ? 'tier-element-text':''} role`} onClick={() => onChange(option)}>
                {icon ? <img src={icon} alt='icon'/> : null}<Text>{option.label}</Text>
              </Container>
            )}
          </Container>
        </Dropdown>
      </StyledDropdownMenu>
    </div>
  )
}

export default DropdownMenu
