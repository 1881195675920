import { getColor } from '../../../../utils'
import { Container } from '../..'
import styled from 'styled-components'


const  StyledDropdownInput = styled(Container)`
  &.disabled{
    pointer-events: none;
    color: ${getColor('inactive')};
    user-select: none;
    .label-container{
      p{
        color: ${getColor('inactive')} !important;
      }
    }
  }
  &.default {
    border: 1px solid ${getColor('sigmaBorder')};
    &.active {
      border: 1px solid ${getColor('brand')}!important;
    }
    position: relative;
    cursor:pointer;
    transition: unset;
    .dropdown-body-container  {
      top: calc(100% + 1px);
      border: 1px solid ${getColor('sigmaBorder')};
      overflow: hidden;
      background: #FBFAFF;
      box-shadow: rgb(22 22 22 / 20%) 0px 4px 8px -2px;
      width: 300px;
      display: flex;
      flex-direction: column;
      .rdrDateRangePickerWrapper{
        font-family: 'GilroyRegular';
        .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
          background: #FBFAFF;
        }
        .rdrDayToday .rdrDayNumber span:after {
          background: ${getColor('brand')}; 
        }
      }
    }
    .options-container {
      z-index: 2;
      max-height: calc(50vh - 72px);
      overflow: auto;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      text-overflow: ellipsis;
      position: relative;
      .role-container {
        padding: 0px 8px;
        border: 1px solid #FBFAFF;
        justify-content: flex-start;
        &.checked-container{
          &:hover{
            border: 1px solid ${getColor('brand')};
          }
        }
        p {
          font-size: 12px;
          font-weight: 400;
        }
        &:hover {
          border: 1px solid ${getColor('sigmaBorder')};
        }
      }
      button.action-button {
        width: 75px;
        align-self: flex-end;
        margin-bottom: 8px;
        margin-right: 8px;
        position: sticky;
        bottom: 10px;
        right: 10px;
        background-color: #FBFAFF;
      }
      .message-container {
        max-width: 150px;
        align-self: center;
        text-align: center;
      }
    }
    .dropdown-body-container {
      z-index: 1;
      transition: unset;
      overflow: visible;
    }
    .trigger-container {
      width: 250px;
      padding: 4px 8px;
      height: 30px;
      
      .label-container {
        p {
          font-weight: 400;
          font-size: 14px;
        }
      }

      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
      }
    }
    .large-container{
      width: 280px;
    }
  }
  .bottom-option{
    display: flex;
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    height: 28px;
    width: 100%;
    border-top: 1px solid ${getColor('sigmaBorder')};
    padding: 4px 8px;
    background-color: #efedf5;
    &.selected-option-label-container{
      justify-content: start;
      p{
        width: 100%;
        text-align: start;
        font-size: 12px;
        margin: 0;
        &.inactive{
          color: #736a8c;
        }
        &.active{
          color: ${getColor('brand')};
        }
      }

    }
    &.select-all-container{
      justify-content: flex-start;
      label{
        align-items: center;
        p{
          font-size: 12px;
          font-weight: 400;
          color: ${getColor('brand')};
        }
      }
    }
  }

`

export default StyledDropdownInput